type ReactEnvName = 'local' | 'development' | 'staging' | 'prod';
type NodeEnvName = 'development' | 'production' | 'test';
type EnvName = ReactEnvName | NodeEnvName;

interface Settings {
  projectRegion: string;
  identityPoolId: string;
  cognitoRegion: string;
  userPoolId: string;
  clientId: string;
  apiGateway: string;
}
interface EnvSettings {
  local: Settings;
  development: Settings;
  staging: Settings;
  test: Settings;
  prod: Settings;
  production: Settings;
}

const currentEnv: EnvName = process.env.REACT_APP_ENV || process.env.NODE_ENV;
// console.log('currentEnv: ', currentEnv);

const envSettings: EnvSettings = {
  local: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:99a84295-cab6-4571-916e-7a55e6f357e7',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_I5pou4vYi',
    clientId: '5d530m3kco7b5bbnllubn7ui7t',
    apiGateway: '',
  },
  development: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:99a84295-cab6-4571-916e-7a55e6f357e7',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_I5pou4vYi',
    clientId: '5d530m3kco7b5bbnllubn7ui7t',
    apiGateway: '',
  },
  staging: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:99a84295-cab6-4571-916e-7a55e6f357e7',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_I5pou4vYi',
    clientId: '5d530m3kco7b5bbnllubn7ui7t',
    apiGateway: '',
  },
  test: {
    // same as staging for fallback
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:99a84295-cab6-4571-916e-7a55e6f357e7',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_I5pou4vYi',
    clientId: '5d530m3kco7b5bbnllubn7ui7t',
    apiGateway: '',
  },
  prod: {
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:99a84295-cab6-4571-916e-7a55e6f357e7',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_I5pou4vYi',
    clientId: '5d530m3kco7b5bbnllubn7ui7t',
    apiGateway: '',
  },
  production: {
    // same as prod for fallback
    projectRegion: 'ap-northeast-1',
    identityPoolId: 'ap-northeast-1:99a84295-cab6-4571-916e-7a55e6f357e7',
    cognitoRegion: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_I5pou4vYi',
    clientId: '5d530m3kco7b5bbnllubn7ui7t',
    apiGateway: '',
  },
};
// console.log(envSettings[currentEnv]);

export const amplifyConfig = {
  aws_project_region: envSettings[currentEnv].projectRegion,
  aws_cognito_identity_pool_id: envSettings[currentEnv].identityPoolId,
  aws_cognito_region: envSettings[currentEnv].cognitoRegion,
  aws_user_pools_id: envSettings[currentEnv].userPoolId,
  aws_user_pools_web_client_id: envSettings[currentEnv].clientId,
};

export const apiGateway = envSettings[currentEnv].apiGateway;

export const cognitoConfig = {
  userPoolId: envSettings[currentEnv].userPoolId,
  clientId: envSettings[currentEnv].clientId,
};

export const awsAmplifyConfig = {
  projectRegion: envSettings[currentEnv].projectRegion,
  identityPoolId: envSettings[currentEnv].identityPoolId,
  cognitoRegion: envSettings[currentEnv].cognitoRegion,
  userPoolId: envSettings[currentEnv].userPoolId,
  userPoolWebClientId: envSettings[currentEnv].clientId,
};
