import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { amplifyConfig } from 'config';
import Amplify, { Auth } from 'aws-amplify';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';

// material
import { TextField, Alert, Stack } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import closeFill from '@iconify/icons-eva/close-fill';
import { MIconButton } from '../../@material-extend';
// hooks
// import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

type InitialValues = {
  email: string;
  afterSubmit?: string;
  code: string;
  newPassword: string;
};

type ResetPasswordFormProps = {
  onSent: VoidFunction;
  onGetEmail: (value: string) => void;
  email: any;
};

const awsConfig = {Auth:{
  projectRegion: amplifyConfig.aws_project_region || '',
  identityPoolId: amplifyConfig.aws_cognito_identity_pool_id || '',
  cognitoRegion: amplifyConfig.aws_cognito_region || '',
  userPoolId: amplifyConfig.aws_user_pools_id || '',
  userPoolWebClientId: amplifyConfig.aws_user_pools_web_client_id || '',
}};

export default function TestPasswordForm({ onSent, onGetEmail, email }: ResetPasswordFormProps) {
  // const { resetPassword } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: email,
      code: '',
      newPassword: ''
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log('aaaaa');
      Amplify.configure(awsConfig);
      const userId = formik.values.email;
      const verificationCode = formik.values.code;
      const newPassword = formik.values.newPassword;
      try {
        // resetPassword?.(values.email);
        await Auth.forgotPasswordSubmit(
          userId,
          verificationCode, // 認証コード
          newPassword       // 新しいパスワード
        )
        .then((data) =>{
          console.log(data);
          // 成功時に表示するトースト
          enqueueSnackbar('Successful password change', {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
          // 成功時にホームへ遷移
          navigate('/');
        })
        .catch((err) => {
          console.log(err);
          // 失敗時にエラー内容をトーストにて表示
          enqueueSnackbar(err.message, {
            variant: 'error',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            ),
          });
        });
        if (isMountedRef.current) {
          onSent();
          onGetEmail(formik.values.email);
          setSubmitting(false);
        }
      } catch (error: any) {
        console.error(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.message });
          setSubmitting(false);
        }
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            {...getFieldProps('email')}
            type="email"
            label="Email address"
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            {...getFieldProps('code')}
            type="code"
            label="Verify code"
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            {...getFieldProps('newPassword')}
            type="newPassword"
            label="New password"
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}  
          />

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Reset Password
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
