// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_ANALYSIS = '/analysis';
const ROOTS_MASTER = '/master';
const ROOTS_SAMPLE = '/sample';
const ROOTS_FILEUPLOAD = '/fileupload'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verifys: path(ROOTS_AUTH, '/verifys'),
  verify: path(ROOTS_AUTH, '/verify'),
  changePassword: path(ROOTS_AUTH, '/change-password')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  account: path(ROOTS_DASHBOARD, '/account'),
};

export const PATH_ANALYSIS = {
  root: ROOTS_ANALYSIS,
  data: path(ROOTS_ANALYSIS, '/data'),
  overview: path(ROOTS_ANALYSIS, '/overview'),
};

export const PATH_MASTER = {
  root: ROOTS_MASTER,
  items: path(ROOTS_MASTER, '/items'),
  users: path(ROOTS_MASTER, '/users'),
};

export const PATH_SAMPLE = {
  root: ROOTS_SAMPLE,
  page1: path(ROOTS_SAMPLE, '/page1'),
  page2: path(ROOTS_SAMPLE, '/page2'),
  page3: path(ROOTS_SAMPLE, '/page3'),
};

export const PATH_FILEUPLOAD = {
  root: ROOTS_FILEUPLOAD,
  monthly: path(ROOTS_FILEUPLOAD, '/monthly'),
  tacs: path(ROOTS_FILEUPLOAD, '/tacs'),
  plan: path(ROOTS_FILEUPLOAD, '/plan'),
  master: path(ROOTS_FILEUPLOAD, '/master'),
  error: path(ROOTS_FILEUPLOAD, '/error'),
  uploadHistory: path(ROOTS_FILEUPLOAD, '/history'),
};