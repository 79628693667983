// routes
import { PATH_FILEUPLOAD } from '../../routes/paths';

// components
import SvgIconStyle from '../../components/SvgIconStyle';

import useAuth from 'hooks/useAuth';
// ----------------------------------------------------------------------

const getIcon = (name: string) => <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />;

const ICONS = {
  monthly: getIcon('ic_file_upload'),
  history: getIcon('ic_article'),
  error: getIcon('ic_report'),
};


// TODO: 所属情報に応じてsidebarConfigを返却するような関数を作成
const sidebarConfig = [
  // FILEUPLOAD
  // ----------------------------------------------------------------------
  {
    subheader: 'fileupload',
    items: [
      { title: 'FOOD', path: PATH_FILEUPLOAD.plan, icon: ICONS.monthly, roles: ['food']},
      { title: 'GLOLOGI', path: PATH_FILEUPLOAD.plan, icon: ICONS.monthly, roles: ['glologi']},
      // { title: 'TACS', path: PATH_FILEUPLOAD.tacs, icon: ICONS.monthly, roles: ['syscom-group', 'tacs'] },
    ],
  },

  // LOGS
  // ----------------------------------------------------------------------
  {
    subheader: 'logs',
    items: [
      { title: 'History', path: PATH_FILEUPLOAD.uploadHistory, icon: ICONS.history, roles: null },
      { title: 'Error List', path: PATH_FILEUPLOAD.error, icon: ICONS.error, roles: null },
    ],
  },
];

console.log(sidebarConfig);

export default sidebarConfig;
