import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import type { AppThunk } from '../store';
import type { LocalhostUser } from '../../@types/localhostUser';
import objFromArray from '../../utils/objFromArray';
import { apiGateway } from '../../config';
import { v4 as uuid } from 'uuid';
import { RowDragComp } from 'ag-grid-community/dist/lib/rendering/rowDragComp';

interface localhostUserGridState {
  isEditing: boolean;
  isDialogOpen: boolean;
  filteringText: string | null;
  rowsPerPage: number;
  rowData: LocalhostUser[] | undefined;
}

const initialState: localhostUserGridState = {
  isEditing: false,
  isDialogOpen: false,
  filteringText: null,
  rowsPerPage: 10,
  rowData: undefined,
};

const slice = createSlice({
  name: 'localhostUserGrid',
  initialState,
  reducers: {
    addRow(state: localhostUserGridState): void {
      const emptyRow: LocalhostUser = {
        uuid: uuid(),
        name: '',
        email: '',
      };
      const newRowData: LocalhostUser[] = state.rowData ? [emptyRow].concat(state.rowData) : [emptyRow];
      state.rowData = newRowData;
    },
    getLocalhostUsers(state: localhostUserGridState, action: PayloadAction<LocalhostUser[]>) {
      const localhostUsers = action.payload;

      state.rowData = localhostUsers;
    },
    createLocalhostUser(state: localhostUserGridState, action: PayloadAction<{ org: LocalhostUser; new: LocalhostUser }>): void {
      const userDataOrg = action.payload.org;
      const userDataNew = action.payload.new;

      state.rowData = state.rowData ? state.rowData.filter((row: LocalhostUser) => row.uuid !== userDataOrg.uuid) : state.rowData;
      state.rowData = state.rowData ? [userDataNew].concat(state.rowData) : [userDataNew];
    },
    updateLocalhostUser(state: localhostUserGridState, action: PayloadAction<LocalhostUser>): void {
      const localhostUser = action.payload;

      state.rowData = state.rowData
        ? state.rowData.map((_row) => {
            if (_row.id === localhostUser.id) {
              return localhostUser;
            }

            return _row;
          })
        : [localhostUser];
    },
    deleteLocalhostUser(state: localhostUserGridState, action: PayloadAction<LocalhostUser>): void {
      const localhostUser = action.payload;

      state.rowData = state.rowData ? state.rowData.filter((row: LocalhostUser) => row.uuid !== localhostUser.uuid) : state.rowData;
    },
    startEditing(state: localhostUserGridState): void {
      state.isEditing = true;
    },
    stopEditing(state: localhostUserGridState): void {
      state.isEditing = false;
    },
    openDialog(state: localhostUserGridState): void {
      state.isDialogOpen = true;
    },
    closeDialog(state: localhostUserGridState): void {
      state.isDialogOpen = false;
    },
  },
});

export const { reducer } = slice;

export const addRow =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.addRow());
  };

export const getLocalhostUsers =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.get<LocalhostUser[]>(apiGateway + '/user', {
      params: {
        filter: {
          orderBy: {
            id: 'asc',
          },
        },
      },
    });

    dispatch(slice.actions.getLocalhostUsers(response.data));
  };

export const createLocalhostUsers =
  (userData: LocalhostUser): AppThunk =>
  async (dispatch): Promise<void> => {
    const userDataOrg = { ...userData };
    console.log(userDataOrg);
    delete userData.uuid;
    const response = await axios.post<LocalhostUser>(apiGateway + '/user', userData);
    const userDataSet = {
      org: userDataOrg,
      new: response.data,
    };

    dispatch(slice.actions.createLocalhostUser(userDataSet));
  };

export const updateLocalhostUsers =
  (userData: LocalhostUser): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.put<LocalhostUser>(apiGateway + '/user/' + userData.id, userData);

    dispatch(slice.actions.updateLocalhostUser(response.data));
  };

export const deleteLocalhostUsers =
  (userData: LocalhostUser): AppThunk =>
  async (dispatch): Promise<void> => {
    if (userData.id) {
      const response = await axios.delete<LocalhostUser>(apiGateway + '/user/' + userData.id);

      dispatch(slice.actions.deleteLocalhostUser(response.data));
    } else {
      dispatch(slice.actions.deleteLocalhostUser(userData));
    }
  };

export const startEditing =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.startEditing());
  };

export const stopEditing =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.stopEditing());
  };

export const openDialog =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.openDialog());
  };

export const closeDialog =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.closeDialog());
  };

export default slice;
