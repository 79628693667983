import { combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';

import { reducer as localhostUsersReducer } from '../slices/localhostUsersGrid';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const rootReducer = combineReducers({
  localhostUsers: localhostUsersReducer,
});

export { rootPersistConfig, rootReducer };
