import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import { INITIAL_PATH } from '../constants';
import ResetTest from 'pages/authentication/RestTest';
// import ChangePassword from 'pages/authentication/ChangePassword';

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  // const isDashboard = pathname.includes('/dashboard');
  const isDashboard = pathname.includes('/fileupload');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed',
            }),
          }}
        />
      }>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [{ path: '/', element: <Navigate to={INITIAL_PATH} replace /> }],
    },

    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verifys', element: <VerifyCode /> },
        { path: 'verify', element: <ResetTest /> },
        { path: 'change-password', element: <ChangePassword /> },
      ],
    },

    // Fileupload Routes
    {
      path: 'fileupload',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '/', element: <Navigate to="/fileupload/plan" replace /> },
        { path: 'monthly', element: <FileUploadPage /> },
        { path: 'tacs', element: <TacsUploadPage /> },
        { path: 'plan', element: <YearUploadPage /> },
        { path: 'master', element: <FileUploadPage /> },
        { path: 'error', element: <ErrorHistoryPage /> },
        { path: 'history', element: <UploadHistoryPage /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
const ChangePassword = Loadable(lazy(() => import('../pages/authentication/ChangePassword')));
// Dashboard
const DashboardAccount = Loadable(lazy(() => import('../pages/dashboard/Account')));
// Analysis
const AnalysisData = Loadable(lazy(() => import('../pages/analysis/Data')));
const AnalysisOverview = Loadable(lazy(() => import('../pages/analysis/Overview')));
// Master
const MasterItems = Loadable(lazy(() => import('../pages/master/Items')));
const MasterUsers = Loadable(lazy(() => import('../pages/master/Users')));
// Sample
const SamplePage1 = Loadable(lazy(() => import('../pages/sample/Page1')));
const SamplePage2 = Loadable(lazy(() => import('../pages/sample/Page2')));
const SamplePage3 = Loadable(lazy(() => import('../pages/sample/Page3')));
// Misc
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
//File Upload
const FileUploadPage = Loadable(lazy(() => import('../pages/fileupload/MonthlyFile')));
const YearUploadPage = Loadable(lazy(() => import('../pages/fileupload/YearFile')));
const TacsUploadPage = Loadable(lazy(() => import('../pages/fileupload/Tacs')));
const UploadHistoryPage = Loadable(lazy(() => import('../pages/upload-history/uploadHistory')));
const ErrorHistoryPage = Loadable(lazy(() => import('../pages/error-history/errorHistory')));