import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { PATH_ANALYSIS, PATH_DASHBOARD, PATH_FILEUPLOAD,  PATH_AUTH } from './routes/paths';

// ----------------------------------------------------------------------

// export const INITIAL_PATH = PATH_ANALYSIS.overview;
export const INITIAL_PATH = PATH_FILEUPLOAD.plan;

export const MENU_OPTIONS = [{ label: 'Change Password', icon: settings2Fill, linkTo: PATH_AUTH.changePassword }];

export const AGGRID_ROWS_PER_PAGE = [
  { value: 10, label: '10 rows/page' },
  { value: 20, label: '20 rows/page' },
  { value: 30, label: '30 rows/page' },
];
